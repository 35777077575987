import React, { useState } from "react";
import { Grid } from "@mui/material";
import Searchbar from "./Searchbar";
import ResponsiveDatePickers from "./DatePicker";
import Dropdown from "./Dropdown";
import Button from "./Button";

const se = [
  { key: "All", value: "All" },
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum", value: "POSTPARTUM" },
  { key: "Beyond", value: "BEYOND" },
];

const statusOptions = [
  { key: "All", value: "All" },
  { key: "Active", value: "ACTIVE" },
  { key: "Blocked", value: "BLOCK" },
];

const FilterBox = ({
  placeholder,
  onFilterChange,
  showAllOption = true,
  dropdownNone,
  showStatusDropdown = false, // New prop to control visibility of the status dropdown
}) => {
  const [searchText, setSearchText] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    showAllOption ? "All" : "Trying to Conceive"
  );
  const [selectedStatus, setSelectedStatus] = useState("All");

  const dropdownOptions = showAllOption
    ? ["All", "Trying to Conceive", "Pregnant", "Postpartum", "Beyond"]
    : ["Trying to Conceive", "Pregnant", "Postpartum", "Beyond"];

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    const select = se.find((option) => option.key === value);
    onFilterChange({
      searchText,
      fromDate,
      toDate,
      selectedValue: select?.value,
      status: selectedStatus,
    });
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    const status = statusOptions.find((option) => option.key === value);
    onFilterChange({
      searchText,
      fromDate,
      toDate,
      selectedValue,
      status: status?.value,
    });
  };

  const handleClear = () => {
    setSearchText("");
    setFromDate(null);
    setToDate(null);
    setSelectedValue(showAllOption ? "All" : "Trying to Conceive");
    setSelectedStatus("All");
    onFilterChange({
      searchText: "",
      fromDate: null,
      toDate: null,
      selectedValue: showAllOption ? "All" : "TRYINGTOCONCEIVE",
      status: "All",
    });
  };

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12} sm={6} md={3}>
        <Searchbar
          placeholder={placeholder}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            onFilterChange({
              searchText: e.target.value,
              fromDate,
              toDate,
              selectedValue,
              status: selectedStatus,
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <ResponsiveDatePickers
          placeholder={"From date"}
          value={fromDate}
          onChange={(date) => {
            setFromDate(date);
            onFilterChange({
              searchText,
              fromDate: date,
              toDate,
              selectedValue,
              status: selectedStatus,
            });
          }}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <ResponsiveDatePickers
          placeholder={"To date"}
          value={toDate}
          onChange={(date) => {
            setToDate(date);
            onFilterChange({
              searchText,
              fromDate,
              toDate: date,
              selectedValue,
              status: selectedStatus,
            });
          }}
          minDate={fromDate}
          maxDate={new Date()}

        />
      </Grid>
      <Grid item xs={12} sm={6} md={showStatusDropdown ? 1 : 2}>
        <Dropdown
          options={dropdownOptions}
          value={selectedValue}
          onChange={handleDropdownChange}
          none={dropdownNone}
        />
      </Grid>
      {showStatusDropdown && (
        <Grid item xs={12} sm={6} md={1}>
          <Dropdown
            options={statusOptions.map((opt) => opt.key)}
            value={selectedStatus}
            onChange={handleStatusChange}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={1}>
        <Button text="Clear" onClick={handleClear} />
      </Grid>
    </Grid>
  );
};

export default FilterBox;

import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";

import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "stage", label: "Stage" },
  { id: "created_date_time", label: "Created At" },
  { id: "action", label: "Action" },
];
const stages = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const ActivationListing = () => {
  const Navigate = useNavigate();
  const [activationList, setActivationList] = useState([]);
  const [render, setRender] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken("listActivateExercise", query);
      console.log(res);
      if (res.responseCode === 200) {
        setActivationList(res?.result || []);
        setFilteredData(res?.result || []);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching recipe list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    console.log(filteredData);
  }, []);

  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    const id = blockId;
    try {
      const query = { subscriptionId: id };
      const res = await putDataHandlerWithToken("listActivateExercise");
      if (res.responseCode === 200) {
        // Update activationist with the updated status
        const updatedRecipeList = activationList.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setActivationList(updatedRecipeList);
        setFilteredData(updatedRecipeList); // Trigger re-render if necessary (depending on your use case)
        setBlockModalOpen(false);
        toast.success("  Subscription status updated successfully");
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.error("Error blocking recipe:", error);
    }
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const handleView = (id) => {
    // Handle view action
    console.log("Block button clicked for row id:", id);
    Navigate(`/activatelisting/viewActivation/${id}`);
  };

  const handleEdit = (id) => {
    // Handle edit action
    Navigate(`/activatelisting/editActivation/${id}`, {
      state: { activationId: id },
    });
  };

  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = activationList.map(({ _id, ...rest }) => rest);
    const headers = Object.keys(dataWithoutId[0]);

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";

    // Append rows of data
    csvContent += dataWithoutId
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");

    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `ActivationListing_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };
  console.log(filteredData);
  return (
    <DashboardLayout sectionName="Subscription" showBackButtonSection={false}>
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>
              <Typography sx={{ ...typographyStyles.h4 }}>
                Activation Listing{" "}
              </Typography>{" "}
              (Add only 4 Activation according to the stages.)
            </Typography>
          </Box>
          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text="+Add Activation"
                onClick={() => Navigate("/activatelisting/addactivation")}
                disabled={setFilteredData?.length >= 4 ? true : false}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((row, index) => ({
                sr_no: index + 1,
                stage: (
                  <Tooltip
                    title={
                      stages.find((option) => option.value === row?.stage)
                        ?.key || "Unknown Stage"
                    }
                    arrow
                  >
                    <span>
                      {(
                        stages.find((option) => option.value === row?.stage)
                          ?.key || "Unknown Stage"
                      ).length > 20
                        ? `${(
                            stages.find((option) => option.value === row?.stage)
                              ?.key || "Unknown Stage"
                          ).substring(0, 20)}...`
                        : stages.find((option) => option.value === row?.stage)
                            ?.key || "Unknown Stage"}
                    </span>
                  </Tooltip>
                ),
                created_date_time: new Date(row.createdAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",

                    hour12: true,
                  }
                ),

                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <VisibilityIcon
                      onClick={() => handleView(row._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        cursor: "pointer",
                      }}
                    />

                    <EditIcon
                      onClick={() => handleEdit(row._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="Subscription"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default ActivationListing;

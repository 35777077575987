import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Divider, Typography, Tooltip } from "@mui/material";
import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import DataNotFound from "../../../components/DataNotFound";
import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "workout_category_name", label: "Workout Section Name" },
  { id: "stage", label: "Stage" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const WorkoutListing = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(false); // State to trigger re-render
  const Navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [noData, setNoData] = useState(false);
  const [stageName, setStageName] = useState([]);

  useEffect(() => {
    const fetchWorkout = async () => {
      if (isAuthenticated && user) {
        try {
          setLoading(true);
          const query = {
            limit: Number.MAX_SAFE_INTEGER,
          };
          const res = await getDataHandlerWithToken(
            "listWorkoutCategory",
            query,
            {}
          );
          console.log(res);
          console.log("filtered data", filteredData);
          if (res.responseCode == 200) {
            setData(res.result.docs);
            setFilteredData(res.result.docs);
            console.log("filtered data", filteredData);
            res.result.docs.length === 0 ? setNoData(true) : setNoData(false);
          } else if (res.responseMessage == 404) {
            setNoData(true);
          }
        } catch (error) {
          console.log("Error while fetching Workouts :", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchWorkout();
  }, [isAuthenticated, user, toggle]);

  const handleView = (id) => {
    // Handle block action
    console.log("Block button clicked for row id:", id);
    Navigate(`/workoutlisting/workoutSubcategory`, {
      state: { categoryId: id },
    });
  };

  const handleEdit = (id) => {
    // Handle edit action
    // navigate(`/category/editcategory/${id}`);
    Navigate(`/workoutlisting/editworkout/${id}`);
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    let categoryId = blockId;
    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockWorkoutCategory",
        {},
        { categoryId }
      );
      if (res.responseCode == 200) {
        const updatedProgramList = data.map((program) =>
          program._id === categoryId ? { ...program, status: status } : program
        );
        setData(updatedProgramList);
        setFilteredData(updatedProgramList);
        setBlockModalOpen(false);
        toast.success(res.responseMessage);

        console.log("blocked");
      }
    } catch (error) {
      // toast.error(res.responseMessage);
      console.log(error);
    }
  };
  const handleFilterChange = (filterCriteria) => {
    let filtered = data;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.workoutCategoryName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter((category) =>
        category.stage.includes(filterCriteria.selectedValue)
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = data.map(({ _id, ...rest }) => rest);
    const headers = ["Sr.No.", "Workout Section Name", "Stage",  "Created Date & Time"];
    const dataToExport = dataWithoutId.map((row, index) => ({
      sr_no: index + 1,
      
   
      workout_category_name: row.workoutCategoryName,
      stage: row.stage,

      
      created_date_time: new Date(row.createdAt).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      status:row.status
    }));

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";

    // Append rows of data
    csvContent += dataToExport
    .map((row) =>
      columns
        .map((col) => {
          const value = row[col.id];
          return typeof value === "string" && value.includes(",")
            ? `"${value}"` // Escape commas in CSV values
            : value;
        })
        .join(",")
    )
    .join("\n");

    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `Workout_Section_Listing_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };

  return (
    <DashboardLayout
      sectionName="Workout Management"
      showBackButtonSection={false}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Workout Section Listing
            </Typography>
          </Box>

          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text=" +Add Section"
                width={"100%"}
                onClick={() => {
                  Navigate("/workoutlisting/addworkout");
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Search by workout section"
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((row, index) => {
                const stageKeys = se
                  .filter((option) => row.stage.includes(option.value))
                  .map((item) => item.key);
                const stageText = stageKeys.join(", ") || "Unknown Stage";
                console.log("Stages for row", index + 1, stageKeys);

                return {
                  sr_no: index + 1,
                  workout_category_name: (
                    <Tooltip title={row.workoutCategoryName} arrow>
                      <span>
                        {row.workoutCategoryName.length > 20
                          ? `${row.workoutCategoryName.substring(0, 20)}...`
                          : row.workoutCategoryName}
                      </span>
                    </Tooltip>
                  ),
                  stage: (
                    <Tooltip title={stageText} arrow>
                      <span>
                        {stageText.length > 20
                          ? `${stageText.substring(0, 20)}...`
                          : stageText}
                      </span>
                    </Tooltip>
                  ),
                  created_date_time: new Date(row.createdAt).toLocaleString(
                    "en-US",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }
                  ),
                  action: (
                    <Box className="row-button-container" sx={{ gap: "15%" }}>
                      <VisibilityIcon
                        variant="outlined"
                        onClick={() => handleView(row._id)}
                        sx={{
                          color: "rgb(225,140,168)",
                          borderColor: "rgb(225,140,168)",
                          cursor: "pointer",
                        }}
                      />
                      <EditIcon
                        onClick={() => handleEdit(row._id)}
                        sx={{
                          color: "rgb(225,140,168)",
                          borderColor: "rgb(225,140,168)",
                          fontFamily: "'Poppins', sans-serif",
                          cursor: "pointer",
                        }}
                      />
                      <BlockIcon
                        onClick={() =>
                          handleBlock(
                            row._id,
                            row.workoutCategoryName,
                            row.status
                          )
                        }
                        sx={{
                          color: row.status == "ACTIVE" ? "green" : "red",
                          borderColor: "rgb(225,140,168)",
                          fontFamily: "'Poppins', sans-serif",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  ),
                };
              })}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="CATEGORY"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default WorkoutListing;

import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, Button, Tooltip, Typography, Divider } from "@mui/material";

import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import {
  getDataHandlerWithToken,
  putDataHandlerWithToken,
} from "../../../apiconfig/service";
import { toast } from "react-hot-toast";
import { saveAs } from "file-saver";

import "../../../scss/main.css";
import { useNavigate } from "react-router-dom";
import BlockModal from "../../../Modal/BlockModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import typographyStyles from "../../../theme/Typography";
import LoadingPage from "../LoadingPage/LoadingPage";
import Loading from "../../../components/Loading";
import DataNotFound from "../../../components/DataNotFound";

const columns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "section_name", label: "Section Name" },
  { id: "program_name", label: "Program Name" },
  
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "action", label: "Action" },
];
const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const ProgramListing = () => {
  const [programList, setProgramList] = useState([]);
  const [render, setRender] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [status, setStatus] = useState();
  const [name, setName] = useState();
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const Navigate = useNavigate();

  const handleView = (id) => {
    // Handle block action
    console.log("Block button clicked for row id:", id);
    Navigate("/program/viewprogram", { state: { programId: id } });
  };

  const handleEdit = (id) => {
    // Handle edit action
    console.log("Edit button clicked for row id:", id);
    Navigate("/program/editprogram", { state: { programId: id } });
  };
  const handleBlock = (id, name, status) => {
    setBlockId(id);
    setStatus(status == "ACTIVE" ? "BLOCK" : "ACTIVE");
    setName(name);
    setBlockModalOpen(true);
  };

  const handleBlockConfirm = async () => {
    // Handle block action
    const id = blockId;
    const query = {
      programId: id,
    };

    try {
      const res = await putDataHandlerWithToken(
        "blockUnblockProgram",
        {},
        query
      );
      console.log(res);
      if (res.responseCode === 200) {
        //setRender(!render);
        setBlockModalOpen(false);
        const updatedProgramList = filteredData.map((program) =>
          program._id === id ? { ...program, status: status } : program
        );
        setProgramList(updatedProgramList);
        setFilteredData(updatedProgramList);
        toast.success(res.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }

    console.log("Block button clicked for row id:", id);
  };
  const handleBlockCancel = () => {
    setBlockModalOpen(false);
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const query = {
        limit: Number.MAX_SAFE_INTEGER,
      };
      const res = await getDataHandlerWithToken("listProgram", query);
      if (res.responseCode === 200) {
        setProgramList(res?.result?.docs || []);
        const filteredPrograms = res?.result?.docs.filter(
          (program) => Array.isArray(program.contentDetails) && program.contentDetails.length > 0
        );
        setData(filteredPrograms);

        setFilteredData(filteredPrograms);
        res?.result?.docs?.length === 0 ? setNoData(true) : setNoData(false);
      } else {
        toast.error(res?.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [render, toggle]);
  const handleFilterChange = (filterCriteria) => {
    let filtered = data;
    console.log(filterCriteria);

    if (filterCriteria.searchText) {
      filtered = filtered.filter((category) =>
        category.programName
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
      console.log("filtered: ", filtered);
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M; // Months are 0-indexed in JavaScript Date
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      console.log("fromDate:", fromDate, " (", fromDate.toISOString(), ")");

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      console.log(toDate);

      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });

      console.log("Filtered categories:", filtered);
    }

    if (
      filterCriteria.selectedValue &&
      filterCriteria.selectedValue !== "All"
    ) {
      console.log(filterCriteria.selectedValue);
      filtered = filtered.filter(
        (category) => category.stage === filterCriteria.selectedValue
      );
    }

    setFilteredData(filtered);
    console.log(filteredData);
  };
  console.log("Program List:", programList);
  const handleDownloadCSV = () => {
    // Remove _id field from data and extract headers
    const dataWithoutId = filteredData.map(({ _id, ...rest }) => rest);
    const headers = ["Sr.No.", "Section Name", "Program Name",  "Stage","Created Date & Time"];
       // Map filtered data to match the table's displayed data
       const dataToExport = dataWithoutId.map((row, index) => ({
        sr_no: index + 1,
        section_name: row.programName,
        program_name: row.categoryName,
        stage: se.find((stage) => stage.value === row.stage)?.key || "Unknown Stage",
        
        created_date_time: new Date(row.createdAt).toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        status: row.status,
      }));

    // Create CSV content with headers
    let csvContent = headers.join(",") + "\n";

    // Append rows of data
    csvContent += dataToExport
    .map((row) =>
      columns
        .map((col) => {
          const value = row[col.id];
          return typeof value === "string" && value.includes(",")
            ? `"${value}"` // Escape commas in CSV values
            : value;
        })
        .join(",")
    )
    .join("\n");

    // Create a Blob object representing the data as a CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    // Create the filename
    const filename = `Program_Section_Without_Topic_${formattedDate}.csv`;

    // Use FileSaver.js to save the Blob as a file
    saveAs(blob, filename);
  };

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      showBackButtonSection={false}
    >
      <Box className="category-container">
        <Box className="heading-container-top">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ ...typographyStyles.h4 }}>
              Section Listing
            </Typography>
          </Box>

         
          <Box className="button-container">
            <Box className="button-sub-container">
              <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
              <BasicButtons
                text="+Add Section"
                onClick={() => {
                  Navigate("/program/addprogram");
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="filter-container">
          <FilterBox
            placeholder="Search by section name"
            onFilterChange={handleFilterChange}
          />
        </Box>

        <Box className="table-container">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh", // Adjust height as needed
              }}
            >
              <Loading />
            </Box>
          ) : filteredData?.length < 1 ? (
            <DataNotFound />
          ) : (
            <PaginationTable
              columns={columns}
              data={filteredData.map((obj, index) => ({
                sr_no: index + 1,
                program_name: (
                  <Tooltip title={obj.categoryName} arrow>
                    <span>
                      {obj.categoryName.length > 20
                        ? `${obj.categoryName.substring(0, 20)}...`
                        : obj.categoryName}
                    </span>
                  </Tooltip>
                ),

                section_name: (
                  <Tooltip title={obj.programName} arrow>
                    <span>
                      {obj.programName.length > 20
                        ? `${obj.programName.substring(0, 20)}...`
                        : obj.programName}
                    </span>
                  </Tooltip>
                ),
                created_date_time: new Date(obj.createdAt).toLocaleString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",

                    hour12: true,
                  }
                ),

                action: (
                  <Box className="row-button-container" sx={{ gap: "15%" }}>
                    <VisibilityIcon
                      variant="outlined"
                      onClick={() => handleView(obj._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        cursor: "pointer",
                      }}
                    />

                    <EditIcon
                      onClick={() => handleEdit(obj._id)}
                      sx={{
                        color: "rgb(225,140,168)",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />

                    <BlockIcon
                      onClick={() =>
                        handleBlock(obj._id, obj.programName, obj.status)
                      }
                      sx={{
                        color: obj.status == "ACTIVE" ? "green" : "red",
                        borderColor: "rgb(225,140,168)",
                        fontFamily: "'Poppins', sans-serif",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ),
              }))}
            />
          )}
        </Box>
      </Box>
      {blockModalOpen && (
        <BlockModal
          title="SECTION"
          status={status}
          name={name}
          onClose={handleBlockCancel}
          onBlock={handleBlockConfirm}
        />
      )}
    </DashboardLayout>
  );
};

export default ProgramListing;

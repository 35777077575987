import React, { useCallback, useEffect, useState } from "react";

import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";

import {
  Box,
  Typography,
  Divider,
  Card,
  CardMedia,
  Grid,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";

import typographyStyles from "../../../theme/Typography";
import BasicButtons from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import InputField from "../../../components/InputField";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { VIDEO_ENC } from "../../../data/sidebarData";
import { modalStyles, commonGridStyle } from "../../../scss/style";
import RemoveIcon from "@mui/icons-material/Remove";

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";

import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  CodeBlock,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  Paragraph,
  SelectAll,
  List,
  ListProperties,
  TextTransformation,
  Underline,
  Undo,
} from "ckeditor5";
import {
  getDataHandlerWithToken,
  postDataHandlerWithToken,
  postDataHandler,
} from "../../../apiconfig/service";
import backBtn from "../../../assets/backBtn.svg";

import "../../../scss/main.css";
import { programSchema } from "../../../schema";
import Cropper from "react-easy-crop";
import ReactModal from "react-modal";

import { cropAndUploadImage } from "../../../utils/cropAndUploadImage";
import Loading from "../../../components/Loading";

const editorConfig = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "style",
      "|",
      "fontSize",
      "fontFamily",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "underline",
      "bulletedList",
      "numberedList",

      "|",
      "horizontalLine",
      "link",
      "insertTable",
      "blockQuote",
      "codeBlock",
      "|",
      "alignment",
      "|",
      "outdent",
      "indent",
    ],
    shouldNotGroupWhenFull: false,
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    HorizontalLine,
    Indent,
    IndentBlock,
    Italic,
    Link,
    Paragraph,
    SelectAll,
    List,
    ListProperties,

    TextTransformation,
    Underline,
    Undo,
  ],
  fontFamily: {
    supportAllValues: true,
  },
  fontSize: {
    options: [10, 12, 14, "default", 18, 20, 22],
    supportAllValues: true,
  },
  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true,
      },
    ],
  },

  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: "https://",
    decorators: {
      toggleDownloadable: {
        mode: "manual",
        label: "Downloadable",
        attributes: {
          download: "file",
        },
      },
    },
  },
  placeholder: "Type or paste your content here!",
};
const addEditStyle = {
  addEditTitle: {
    ...typographyStyles.h4,
  },
  addEditLabel: {
    ...typographyStyles.h6,
  },
  addRemoveButton: {
    textTransform: "none",
    backgroundColor: "rgb(225,140,168)",
    color: "white",
    fontWeight: "400",
    fontSize: "15px",
    fontFamily: "'Poppins', sans-serif",
    boxShadow: "0 0 0.938rem 0 rgba(0, 0, 0, 0.2)",
    marginTop: "2px",
    marginLeft: "2px",
    "&:hover": {
      backgroundColor: "white",
      color: "rgb(225,140,168)",
    },
  },
};

const dropdownOptions = [
  "Trying to Conceive",
  "Pregnant",
  "Postpartum (0-6 Weeks After Birth)",
  "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
];

const se = [
  { key: "Trying to Conceive", value: "TRYINGTOCONCEIVE" },
  { key: "Pregnant", value: "PREGNANT" },
  { key: "Postpartum (0-6 Weeks After Birth)", value: "POSTPARTUM" },
  {
    key: "Beyond -(6 Weeks + After Birth (Weeks, months, Years))",
    value: "BEYOND",
  },
];

const EditProgram = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [program, setProgram] = useState({});
  const [thumbnail, setThumbnail] = useState(null);
  const [editorContent, setEditorContent] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [programId, setProgramId] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageIndexForCropping, setImageIndexForCropping] = useState(null);
  const [descriptionIndexForCropping, setDescriptionIndexForCropping] =
    useState(null);
  const [fetch, setfetch] = useState(false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [charCount, setCharCount] = useState(0);
  const charLimit = 2400; // Set your character limit here
  const [contentDetails, setContentDetails] = useState([
    {
      description: "",
      images: [],
      videos: [],
      accordianFeatures:[],
    },
  ]);
  const [descriptionImageIndex, setDescriptionImageIndex] = useState(null);
  const [editorVisibility, setEditorVisibility] = useState([]);

  // Toggle editor visibility
  const handleToggleEditor = (contentIndex) => {
    if (editorVisibility[contentIndex]) {
      // Clear the editor content when closing it
      setContentDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails[contentIndex].description = ""; // Clear description or data field
        return updatedDetails;
      });
    }

    setEditorVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[contentIndex] = !prevVisibility[contentIndex]; // Toggle visibility at the index
      return updatedVisibility;
    });
  };

  useEffect(() => {
    // Initialize the editor visibility based on whether the description exists
    const visibilityArray = contentDetails.map(
      (content) => !!content.description
    ); // true if description exists, false otherwise
    setEditorVisibility(visibilityArray);
  }, [contentDetails]);

  const navigate = useNavigate();
  const location = useLocation();

  const fetchProgram = async () => {
    const programIdFromLocation = location.state?.programId;
    setProgramId(programIdFromLocation);

    const query = {
      programId: programIdFromLocation,
    };
    try {
      setfetch(true);
      const res = await getDataHandlerWithToken("viewProgram", query);
      if (res.responseCode === 200) {
        const response = res.result;
        console.log(response);
        setProgram(res.result);
        setEditorContent(response.description);
        setSelectedValue(
          se.find((option) => option.value === res.result.stage)?.key || ""
        );
        setCategoryId(response.categoryId);
        const removeSecurityFromVideoKey = (videoKey) => {
          if (videoKey && videoKey.endsWith(`${VIDEO_ENC}`)) {
            return videoKey.replace(`${VIDEO_ENC}`, ""); // Remove the security string
          }
          return videoKey; // Return as-is if no security string found
        };

        const sections = res?.result?.contentDetails?.map(
          ({ _id, ...rest }) => ({
            ...rest,
            videos: rest.videos?.map(({ _id, ...video }) => ({
              ...video,
              videoHls: removeSecurityFromVideoKey(video.videoHls), // Remove security string from videoKey
            })),
            images: rest.images?.map(({ _id, ...image }) => ({
              ...image,
            })),
            accordianFeatures: rest.accordianFeatures?.map(({ _id, ...image }) => ({
              ...image,
            })),
          })
        );
        setContentDetails(sections);

        // Initialize the thumbnail state with the fetched image URL
        setThumbnail(response.programThumbnailImage);

        formik.setValues({
          selectRange:
            se.find((option) => option.value === response.stage)?.key || "",
          selectCategory: response.categoryName || "",
          programName: response.programName || "",
          categoryImage: response.programThumbnailImage,
          contentDetails: contentDetails || [
            {
              description: "",
              images: [],
              videos: [],
              accordianFeatures:[]
            },
          ],
        });
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setfetch(false);
    }
  };

  const handleAddVideo = (sectionIndex) => {
    setContentDetails((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].videos.push({
        title: "",
        videoScript: "",
        videoHls: "",
      });
      return updatedSections;
    });
  };

  const handleAddAccordian = (sectionIndex) => {
    setContentDetails((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].accordianFeatures.push({});
      return updatedSections;
    });
  };
  const handleRemoveAccordian = (sectionIndex, exerciseIndex) => {
    setContentDetails((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].accordianFeatures.splice(exerciseIndex, 1);
      return updatedSections;
    });
  };
  const handleAccordianChange = (sectionIndex, exerciseIndex, field, value) => {
    setContentDetails((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].accordianFeatures[exerciseIndex][field] =
        value;
      return updatedSections;
    });
  };
  const handleVideoChange = (sectionIndex, exerciseIndex, field, value) => {
    setContentDetails((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].videos[exerciseIndex][field] = value;
      return updatedSections;
    });
  };

  const handleRemoveVideo = (sectionIndex, exerciseIndex) => {
    setContentDetails((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].videos.splice(exerciseIndex, 1);
      return updatedSections;
    });
  };
  const handleAddImages = (sectionIndex) => {
    setContentDetails((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].images.push({
        redirectUrl: "",
        image: "",
      });
      return updatedSections;
    });
  };

  const handleImageUrlChange = (sectionIndex, exerciseIndex, field, value) => {
    setContentDetails((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].images[exerciseIndex][field] = value;
      return updatedSections;
    });
  };

  const handleRemoveImages = (sectionIndex, exerciseIndex) => {
    setContentDetails((prevSections) => {
      const updatedSections = [...prevSections];
      updatedSections[sectionIndex].images.splice(exerciseIndex, 1);
      return updatedSections;
    });
  };

  const handleFileSelectImage = async (contentIndex, imagesIndex, event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setImageIndexForCropping(0); // Assuming adding new image to existing list
      setDescriptionIndexForCropping(contentIndex);
      setDescriptionImageIndex(imagesIndex);
      setIsModalOpen(true);
    }
  };

  const handleRemoveImage = (sectionIndex, imageIndex) => {
    const newContentDetails = [...contentDetails];
    newContentDetails[sectionIndex].images[imageIndex].image = "";
    setContentDetails(newContentDetails);
  };
  const handleAddValueContent = () => {
    setContentDetails([
      ...contentDetails,
      {
        description: "",
        images: [],
        videos: [],
      },
    ]);
  };
  const handleRemoveStepContent = (index) => {
    if (contentDetails.length > 1) {
      const newContentDetails = contentDetails.filter((_, i) => i !== index);
      setContentDetails(newContentDetails);
    } else {
      toast.error("You must have at least one description section.");
    }
  };

  useEffect(() => {
    fetchProgram();
  }, [location.state?.programId]);

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    formik.setFieldValue("selectRange", value);

    // Reset the category when the stage changes
    setSelectedCategory("");
    setCategoryId("");

    // Clear the category field in the formik values
    formik.setFieldValue("selectCategory", "");

    filterCategoriesByStage(value);
  };

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setIsModalOpen(true);
    }
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    formik.setFieldValue("selectCategory", value);

    const selectedCategoryObj = categoryList.find(
      (obj) => obj.categoryName === value
    );

    if (selectedCategoryObj) {
      setCategoryId(selectedCategoryObj._id);
    } else {
      setCategoryId(""); // Reset if no category is found
    }
  };

  const [mydata, setMydata] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getDataHandlerWithToken("listCategory", {
        limit: Number.MAX_SAFE_INTEGER,
      });
      if (res.responseCode === 200) {
        setMydata(res.result.docs || []);
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = useCallback(async () => {
    setLoading(true);
    try {
      const cloudinaryUrl = await cropAndUploadImage(
        selectedImage,
        croppedAreaPixels
      );
      if (imageIndexForCropping === null) {
        // If cropping thumbnail
        setThumbnail(cloudinaryUrl);
        // setThumbnailPreview(cloudinaryUrl);
        formik.setFieldValue("categoryImage", cloudinaryUrl);
      } else {
        // If cropping description image
        const newContentDetails = [...contentDetails];
        if (descriptionIndexForCropping !== null) {
          // Add new image to the existing images array
          newContentDetails[descriptionIndexForCropping].images[
            descriptionImageIndex
          ]["image"] = cloudinaryUrl;
          setContentDetails(newContentDetails);
        }
      }
      setIsModalOpen(false);
      setSelectedImage(null);
    } catch (e) {
      toast.error("Error while cropping or uploading image");
    } finally {
      setLoading(false);
    }
  }, [
    croppedAreaPixels,
    selectedImage,
    imageIndexForCropping,
    descriptionIndexForCropping,
    descriptionImageIndex,
    contentDetails,
  ]);
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const filterCategoriesByStage = (selectedValue) => {
    const stageValue = se.find((option) => option.key === selectedValue)?.value;
    if (stageValue) {
      const filteredCategories = mydata.filter(
        (category) =>
          category.stage === stageValue &&
          category.categoryName != "Recipes" &&
          category.categoryName != "Workout" &&
          category.status !== "BLOCK"
      );
      setCategoryList(filteredCategories);
    }
  };

  useEffect(() => {
    fetchData();
    filterCategoriesByStage(selectedValue);
  }, [selectedValue]);

  const formik = useFormik({
    initialValues: {
      selectRange: "",
      selectCategory: "",
      programName: "",
      categoryImage: null,
      contentDetails: [
        {
          description: "",
          images: [],
          videos: [],
        },
      ],
    },
    validationSchema: programSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const data = {
        stage: se.find((option) => option.key === formik.values.selectRange)
          ?.value,

        programId: programId,

        categoryId: categoryId,
        programName: values.programName,
        programThumbnailImage: thumbnail
          ? thumbnail
          : program.programThumbnailImage,
        contentDetails: contentDetails,
      };
      console.log(data);

      try {
        setLoading(true);
        const res = await postDataHandlerWithToken("addUpdateProgram", data);
        if (res.responseCode === 200) {
          toast.success("Section updated successfully.");
          navigate("/program");
        } else {
          toast.error(res.data.responseMessage);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
  });
  if (fetch) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Takes the full viewport height
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <DashboardLayout
      sectionName="Content - Program Management"
      title="Edit Program"
    >
      <Box className="heading-container-top">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box>
            <img
              src={backBtn}
              alt="backBtn"
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            />
          </Box>
          <Typography sx={{ ...typographyStyles.h4 }}>Edit Section</Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="add-edit-container">
        <form
          className="add-edit-content-section"
          onSubmit={formik.handleSubmit}
        >
          <Box className="add-edit-content">
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Stage:
                </Typography>
              </Box>
              <Dropdown
                options={dropdownOptions}
                value={formik.values.selectRange}
                onChange={handleDropdownChange}
                placeholder="Select Stage"
              />
              {formik.errors.selectRange && formik.touched.selectRange ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {formik.errors.selectRange}
                </p>
              ) : null}
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Select Program:
                </Typography>
              </Box>
              <Dropdown
                options={categoryList.map((category) => category.categoryName)}
                value={formik.values.selectCategory}
                onChange={handleCategoryChange}
                placeholder="Select Category"
              />
              {formik.errors.selectCategory && formik.touched.selectCategory ? (
                <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                  {formik.errors.selectCategory}
                </p>
              ) : null}
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Section Name:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  type="text"
                  name="programName"
                  value={formik.values.programName}
                  maxLength={50}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter section name"
                />
                {formik.errors.programName && formik.touched.programName ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {formik.errors.programName}
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={addEditStyle.addEditLabel}>
                  Upload Thumbnail Image:
                </Typography>
              </Box>
              <Box className="add-edit-input-field">
                <InputField
                  name="categoryImage"
                  type="file"
                  onChange={handleThumbnailChange}
                  onBlur={formik.handleBlur}
                  acceptedFile="image/*"
                />
                {formik.errors.categoryImage && formik.touched.categoryImage ? (
                  <p style={{ color: "red", marginTop: "5px", float: "left" }}>
                    {formik.errors.categoryImage}
                  </p>
                ) : null}
                {/* Display the thumbnail preview */}
                {thumbnail && (
                  <Box mt={2}>
                    <Card
                      sx={{ maxWidth: 300, maxHeight: "auto", margin: "5px" }}
                    >
                      <CardMedia component="img" image={thumbnail} />
                    </Card>
                  </Box>
                )}
              </Box>
            </Box>

            <Box className="add-edit-input-container">
              <Box className="add-edit-input-label">
                <Typography sx={{ ...typographyStyles.h6 }}>
                  Description Section:
                </Typography>
              </Box>
              <Box
                className="add-edit-input-field ckeditor-container"
                sx={{ width: "100%" }}
              >
                {contentDetails?.map((content, contentIndex) => (
                  <Box key={contentIndex} mb={2}>
                    <Grid sx={commonGridStyle}>
                      <Box className="add-edit-input-field">
                        <Button
                          sx={{
                            backgroundColor: "rgb(225, 140, 168)",
                            textTransform: "none",

                            minWidth: "30%",
                            
                          }}
                          variant="contained"
                          onClick={() => handleToggleEditor(contentIndex)}
                          startIcon={
                            editorVisibility[contentIndex] ? (
                              <RemoveIcon />
                            ) : (
                              <AddIcon />
                            )
                          }
                        >
                       {editorVisibility[contentIndex] ? "Remove Description" : "Add Description"}
                        </Button>

                        <Grid className="ckeditor-container" mb={1} mt={1} >
                          {editorVisibility[contentIndex] && (
                            <CKEditor
                         
                              editor={ClassicEditor}
                              data={content.description || ""}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                const newContentDetails = [...contentDetails];
                                newContentDetails[contentIndex].description =
                                  data;
                                setContentDetails(newContentDetails);
                                formik.setFieldValue(
                                  "contentDetails",
                                  newContentDetails
                                );
                              }}
                              config={editorConfig}
                              className="StaticEditor"
                            />
                          )}

                          {/* Formik validation errors */}
                          {formik.touched.contentDetails?.[contentIndex]
                            ?.description &&
                            formik.errors.contentDetails?.[contentIndex]
                              ?.description && (
                              <p
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  float: "left",
                                }}
                              >
                                {
                                  formik.errors.contentDetails[contentIndex]
                                    .description
                                }
                              </p>
                            )}
                        </Grid>
                      </Box>

                      <Box className="add-edit-input-field">
                        <Box>
                          {content?.images?.map((image, imageIndex) => (
                            <Card key={imageIndex} sx={{ padding: 1, mb: 2 }}>
                              <InputField
                                placeholder={` Image Redirect URL ${
                                  imageIndex + 1
                                }`}
                                value={image.redirectUrl}
                                maxLength={200}
                                onChange={(e) =>
                                  handleImageUrlChange(
                                    contentIndex,
                                    imageIndex,
                                    "redirectUrl",
                                    e.target.value
                                  )
                                }
                                fullWidth
                              />

                              <Grid
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  sx={{
                                    backgroundColor: "rgb(225, 140, 168)",
                                    textTransform: "none",
                                    mt: 1,
                                  }}
                                  variant="contained"
                                  component="label"
                                  startIcon={<UploadIcon />}
                                >
                                  Upload
                                  <input
                                    type="file"
                                    accept="image/*"
                                    hidden
                                    onChange={(event) =>
                                      handleFileSelectImage(
                                        contentIndex,
                                        imageIndex,
                                        event
                                      )
                                    }
                                  />
                                </Button>

                                <IconButton
                                  sx={{ ...addEditStyle.addRemoveButton }}
                                  onClick={() =>
                                    handleRemoveImages(contentIndex, imageIndex)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Grid>

                              {image.image && (
                                <Card sx={{ maxWidth: 200, margin: "5px" }}>
                                  <CardMedia
                                    component="img"
                                    image={image.image}
                                    alt={`image ${imageIndex + 1}`}
                                  />
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveImage(
                                        contentIndex,
                                        imageIndex
                                      )
                                    }
                                    sx={{ color: "rgb(225, 140, 168)" }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Card>
                              )}
                            </Card>
                          ))}
                        </Box>
                        <Button
                          sx={{
                            backgroundColor: "rgb(225, 140, 168)",
                            textTransform: "none",
                            mb: 2,
                            width: "30%",
                          }}
                          variant="contained"
                          component="label"
                          onClick={() => handleAddImages(contentIndex)}
                          startIcon={<AddIcon />}
                        >
                          Add Images
                        </Button>
                      </Box>

                      <Box className="add-edit-input-field">
                        {content?.videos?.map((video, videoIndex) => (
                          <Box key={videoIndex}>
                            <Grid
                              container
                              alignItems="center"
                              sx={commonGridStyle}
                            >
                              {/* Video Title */}
                              <Grid item xs={12}>
                                <InputField
                                  placeholder={`Video title ${videoIndex + 1}`}
                                  value={video.title}
                                  maxLength={200}
                                  onChange={(e) =>
                                    handleVideoChange(
                                      contentIndex,
                                      videoIndex,
                                      "title",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>

                              {/* Video Script */}
                              <Grid item xs={12} mt={2}>
                                <InputField
                                  placeholder={`Video script ${videoIndex + 1}`}
                                  value={video.videoScript}
                                  onChange={(e) =>
                                    handleVideoChange(
                                      contentIndex,
                                      videoIndex,
                                      "videoScript",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>

                              {/* Video HLS Link */}
                              <Grid item xs={12} mt={2} mb={2}>
                                <InputField
                                  placeholder={`Video HLS link ${
                                    videoIndex + 1
                                  }`}
                                  value={video.videoHls}
                                  onChange={(e) =>
                                    handleVideoChange(
                                      contentIndex,
                                      videoIndex,
                                      "videoHls",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              </Grid>

                              {/* Add/Remove buttons for videos */}
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    mt: "5px",
                                  }}
                                >
                                  {/* Remove Video Button */}
                                  {content.videos.length > 0 && (
                                    <IconButton
                                      sx={{ ...addEditStyle.addRemoveButton }}
                                      onClick={() =>
                                        handleRemoveVideo(
                                          contentIndex,
                                          videoIndex
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        ))}
                        <Button
                          sx={{
                            backgroundColor: "rgb(225, 140, 168)",
                            textTransform: "none",
                            width: "30%",
                          }}
                          variant="contained"
                          component="label"
                          onClick={() => handleAddVideo(contentIndex)}
                          startIcon={<AddIcon />}
                        >
                          Add Videos
                        </Button>
                      </Box>

                      <Box className="add-edit-input-field">
                        {content?.accordianFeatures?.map(
                          (accordian, accordianIndex) => (
                            <Box key={accordianIndex}>
                              <Grid container sx={commonGridStyle} mt={2}>
                                <Grid item xs={6} md={12} width="100%" mb={2}>
                                  <InputField
                                    label="Title"
                                    placeholder={`Accordian title ${
                                      accordianIndex + 1
                                    }`}
                                    value={accordian.title}
                                    onChange={(e) =>
                                      handleAccordianChange(
                                        contentIndex,
                                        accordianIndex,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                  />

                                  {/* {formik.touched.accordianFeatures?.[
                                  accordianIndex
                                ]?.title &&
                                  formik.errors.accordianFeatures?.[
                                    accordianIndex
                                  ]?.title && (
                                    <p
                                      style={{
                                        color: "red",
                                        marginTop: "5px",
                                        float: "left",
                                      }}
                                    >
                                      {
                                        formik.errors
                                          .accordianFeatures?.[accordianIndex]
                                          ?.title
                                      }
                                    </p>
                                  )} */}
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={12}
                                  className="add-edit-input-field ckeditor-container"
                                  sx={{ width: "100%" }}
                                >
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={accordian.description || ""}
                                    onChange={(e, editor) =>
                                      handleAccordianChange(
                                        contentIndex,
                                        accordianIndex,
                                        "description",
                                        editor.getData()
                                      )
                                    }
                                    config={editorConfig}
                                    className="StaticEditor"
                                  />

                                  {/* {formik.touched.accordianFeatures?.[
                                  index
                                ]?.description &&
                                  formik.errors.accordianFeatures?.[
                                    index
                                  ]?.description && (
                                    <p
                                      style={{
                                        color: "red",
                                        marginTop: "5px",
                                        float: "left",
                                      }}
                                    >
                                      {
                                        formik.errors
                                          .accordianFeatures?.[index]
                                          ?.description
                                      }
                                    </p>
                                  )} */}
                                </Grid>
                                <Grid item xs={12}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      gap: "10px",
                                      mt: "5px",
                                    }}
                                  >
                                    {
                                      <IconButton
                                        sx={{ ...addEditStyle.addRemoveButton }}
                                        onClick={() =>
                                          handleRemoveAccordian(
                                            contentIndex,
                                            accordianIndex
                                          )
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    }
                                    {/* {index ===
                                    accordionTitleDescriptions.length - 1 && (
                                    <IconButton
                                      onClick={() =>
                                        handleAddValueTapsAcco(
                                          accordionTitleDescriptions,
                                          setAccordionTitleDescriptions
                                        )
                                      }
                                      sx={addEditStyle.addRemoveButton}
                                    >
                                      <AddCircleOutlineRoundedIcon />
                                    </IconButton>
                                  )} */}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          )
                        )}
                        <Button
                          sx={{
                            backgroundColor: "rgb(225, 140, 168)",
                            textTransform: "none",
                            mb: 2,
                            width: "30%",
                            mt: 2,
                          }}
                          variant="contained"
                          component="label"
                          onClick={() => handleAddAccordian(contentIndex)}
                          startIcon={<AddIcon />}
                        >
                          Add Accordian
                        </Button>
                      </Box>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          {/* Remove content if more than 1 */}
                          {contentDetails?.length > 1 && (
                            <Grid
                              item
                              xs={2}
                              md={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <IconButton
                                onClick={() =>
                                  handleRemoveStepContent(
                                    contentIndex,
                                    contentDetails,
                                    setContentDetails,
                                    "contentDetails"
                                  )
                                }
                                sx={{ ...addEditStyle.addRemoveButton }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          )}
                          {/* Add new content */}
                          {contentIndex === contentDetails?.length - 1 && (
                            <Grid
                              item
                              xs={2}
                              md={1}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <IconButton
                                onClick={() =>
                                  handleAddValueContent(
                                    contentDetails,
                                    setContentDetails
                                  )
                                }
                                sx={addEditStyle.addRemoveButton}
                              >
                                <AddCircleOutlineRoundedIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            className="add-edit-button-section"
            sx={{ alignItems: "center", justifyContent: "center" }}
          >
            <BasicButtons
              width={"25%"}
              onClick={() => navigate("/program")}
              title="Back"
              text={"Back"}
            />
            <BasicButtons
              width={"25%"}
              type="submit"
              loading={loading}
              disabled={loading}
              title="Save"
              text={"Edit Section"}
            />
          </Box>
        </form>
      </Box>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => handleCancel()}
        style={modalStyles}
        contentLabel="Crop Image"
      >
        <div style={modalStyles.cropperContainer}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={17 / 12}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              gap: 5,
            }}
          >
            <BasicButtons text="Cancel" onClick={handleCancel} />
            <BasicButtons text="Crop" onClick={handleCrop} loading={loading} />
          </Box>
        </div>
      </ReactModal>
    </DashboardLayout>
  );
};

export default EditProgram;
